<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="订单编号" prop="id">
                <a-input v-model="queryParam.id" placeholder="请输入订单编号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="三方支付订单号" prop="outTradeNo">
                <a-input v-model="queryParam.outTradeNo" placeholder="请输入三方支付订单号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单状态" prop="orderStatus">
                <a-select placeholder="请选择订单状态" style="width: 100%" v-model="queryParam.orderStatus" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.OrderSiteStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="审核状态" prop="orderStatus">
                  <a-select placeholder="请选择审核状态" style="width: 100%" v-model="queryParam.auditStatus" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.OrderCancelAuditStatusEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="场地名称" prop="siteName">
                  <a-input v-model="queryParam.siteName" placeholder="场地名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="姓名/公司名称" prop="subjectName">
                  <a-input v-model="queryParam.subjectName" placeholder="姓名/公司名称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="联系电话" prop="contactPhone">
                  <a-input v-model="queryParam.contactPhone" placeholder="联系电话" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="付款区间" prop="payAmountRange">
                  <a-input-number style="width: 45%" :maxLength="12" v-model="queryParam.startAmount" allow-clear/> -
                  <a-input-number style="width: 45%" :maxLength="12" v-model="queryParam.endAmount"  allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="是否超时" prop="isTimeout">
                  <a-select placeholder="请选择是否超时" style="width: 100%" v-model="queryParam.isTimeout" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="是否开票" prop="isInvoice">
                  <a-select placeholder="请选择是否开票" style="width: 100%" v-model="queryParam.isInvoice" allowClear>
                    <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                     :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="下单开始时间" prop="createBeginTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createBeginTime" @change = "startTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="下单结束时间" prop="createEndTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createEndTime" @change = "endTimeChange" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>

              </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane key="" tab="全部订单"/>
        <a-tab-pane key="10" tab="待审核"/>
        <a-tab-pane v-for="pane in this.customDict.OrderSiteStatusEnum" :key="pane.type" :tab="pane.name">
        </a-tab-pane>
      </a-tabs>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['order:site:edit']">
          <a-icon type="edit" />修改
        </a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:site:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <audit-form
        ref="auditForm"
        @ok="getList"
      />
      <pay-form
        ref="payForm"
        @ok="getList"
      />
      <timeout-form
        ref="timeoutForm"
        @ok="getList"
      />
      <detail-form
        ref="detailForm"
        @ok="getList"
      />
      <remark-form
        ref="remarkFrom"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="orderStatus" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.OrderSiteStatusEnum" :value="record.orderStatus"/>
        </span>
        <span slot="isTimeout" slot-scope="text, record">
            <!-- 重写自定义了个字典组件-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isTimeout"/>
        </span>
        <span slot="operation" slot-scope="text, record">
           <a-divider type="vertical"/>
            <a @click="$refs.detailForm.handleDetail(record.id)">
              <a-icon type="eye" />详情
            </a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="double-right" />
              更多
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="$refs.remarkFrom.handleRemark(record)" >
                  <a-icon type="edit" />
                  备注
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:site:pay']" v-if="record.orderStatus===3010">
                <a @click="$refs.payForm.handlePay(record, undefined)"   v-hasPermi="['order:site:pay']">
                  <a-icon type="edit" />
                  确认支付
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:site:audit']" v-if="record.auditStatus===10 && record.orderStatus!==3030">
                <a @click="$refs.auditForm.handleAudit(record, undefined)"   v-hasPermi="['order:site:audit']">
                  <a-icon type="edit" />
                  审核
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:site:timeout']"  v-if="!record.isTimeout &&(record.orderStatus===3020|| record.orderStatus===3030)">
                <a @click="$refs.timeoutForm.handleCreateTimeOut(record, undefined)"   v-hasPermi="['order:site:timeout']">
                  <a-icon type="check-circle" />
                  生成超时订单
                </a>
              </a-menu-item>
              <a-menu-item v-hasPermi="['order:site:cancel']" v-if="record.auditStatus==null && (record.orderStatus===3010|| record.orderStatus===3020)">
                <a @click="handleCancel(record)"   v-hasPermi="['order:site:cancel']">
                  <a-icon type="check-circle" />
                  取消订单
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSite,listSite, delSite,cancelSite } from '@/api/order/site'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import TimeoutForm from './modules/TimeoutForm'
import AuditForm from './modules/AuditForm'
import DetailForm from './modules/DetailForm'

import { tableMixin } from '@/store/table-mixin'
import PayForm from "./modules/PayForm";
import RemarkForm from "@/views/order/order/modules/RemarkForm";

export default {
  name: 'Site',
  components: {
    PayForm,
    CustomDictTag,
    AuditForm,
    DetailForm,
    TimeoutForm,
    CreateForm,
    RemarkForm,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      activeKey: null,//被选中的tab
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewVisible: false,
      previewAvatar: '',
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: null,
        siteId: null,
        siteName: null,
        auditStatus: null,
        startAmount: null,
        endAmount: null,
        orderStatus: null,
        subjectName: null,
        contactPhone: null,
        personNum: null,
        theme: null,
        price: null,
        orderAmount: null,
        payAmount: null,
        payTime: null,
        outTradeNo: null,
        isTimeout: null,
        isInvoice: null,
        createBeginTime: null,
        createEndTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '订单编号',
          dataIndex: 'id',
          align: 'center'
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatusName',
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'auditStatusName',
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          align: 'center'
        },
        {
          title: '场地名称',
          dataIndex: 'siteName',
          align: 'center'
        },
        {
          title: '场次',
          dataIndex: 'siteTimeName',
          align: 'center'
        },
        {
          title: '姓名/公司名称',
          dataIndex: 'subjectName',
          align: 'center'
        },
        {
          title: '联系电话',
          dataIndex: 'contactPhone',
          align: 'center'
        },
        {
          title: '预计人数',
          dataIndex: 'personNum',
          align: 'center'
        },
        {
          title: '活动主题',
          dataIndex: 'theme',
          align: 'center'
        },
        {
          title: '应付款 ',
          dataIndex: 'payAmount',
          align: 'center'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          align: 'center'
        },
        {
          title: '是否超时订单',
          dataIndex: 'isTimeout',
          scopedSlots: { customRender: 'isTimeout' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '10%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询订单-场地订单列表 */
    getList () {
      this.loading = true
     pageSite(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    //切换tab
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      //待审核
      if (index == 10) {
        this.queryParam.auditStatus = index;
      } else {
        this.queryParam.orderStatus = index;
        this.queryParam.auditStatus = null;
      }
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        siteId: undefined,
        siteSnapshot: undefined,
        siteTime: undefined,
        subjectName: undefined,
        contactPhone: undefined,
        personNum: undefined,
        theme: undefined,
        price: undefined,
        orderDay: undefined,
        totalDay: undefined,
        orderAmount: undefined,
        payAmount: undefined,
        payTime: undefined,
        outTradeNo: undefined,
        isTimeout: undefined,
        isInvoice: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    startTimeChange(date, dateString){
      this.queryParam.createBeginTime = dateString
    },
    endTimeChange(date, dateString){
      this.queryParam.createEndTime = dateString
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids= []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSite(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleCancel (record) {
      var that = this
      this.$confirm({
        title: '是否确认取消?',
        onOk () {
          return cancelSite({id:record.id})
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '取消成功',
                3
              )
            })
        },
        onCancel () {}
      })
      this.getList();
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('order/site/export', {
            ...that.queryParam
          }, `订单-场地订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
